.navbar {
  height: 70px;
  position: sticky;
  top: 0;
  z-index: 500;
  width: 100%;
  display: flex;
  background-color: white;
  align-items: center;
  box-shadow: 0px 3px 44px #010e3f24;
  padding-inline: 2.5%;
}
